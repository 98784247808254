var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page__type"},[_c('y-list-page',{ref:"listPageRef",attrs:{"pro-form-style":{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },"form-style":{ textAlign: 'right' },"inline":"","label-width":"100px","model":_vm.model,"fields":_vm.fields,"pagination":true,"submitter":{
      submitText: '搜索',
      resetButtonProps: {
        style: {
          display: 'none'
        }
      }
    },"columns":_vm.columns,"fetch":_vm.fetchFn},scopedSlots:_vm._u([{key:"form-before-addon",fn:function(){return [_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("添加案例类别")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }